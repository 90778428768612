'use client';

import { Avatar, Group, Menu, Text, UnstyledButton, rem } from '@mantine/core';
import type { User } from '@supabase/supabase-js';
import {
  IconChevronDown,
  IconCreditCard,
  IconLogout,
  IconSettings,
  IconUser,
} from '@tabler/icons-react';
import Link from 'next/link';
import { forwardRef, useState } from 'react';
import { signOut } from '../actions';
import classes from './AccountMenu.module.css';

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  image: string;
  name: string;
  email: string;
  opened: boolean;
}

const iconSize = { width: rem(14), height: rem(14) };

// eslint-disable-next-line react/display-name
export const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, name, email, opened, ...others }: UserButtonProps, ref) => (
    <UnstyledButton ref={ref} classNames={classes} {...others}>
      <Group gap="sm" preventGrowOverflow justify="space-between">
        <Group gap={6} preventGrowOverflow>
          <Avatar size="sm" src={image} />
          <div>
            <Text fz="sm" lh={1.1}>
              {name}
            </Text>
            <Text fz="xs" c="dimmed" lh={1}>
              {email}
            </Text>
          </div>
        </Group>
        <IconChevronDown
          style={{
            ...iconSize,
          }}
          className={classes.icon}
          stroke={1.5}
        />
      </Group>
    </UnstyledButton>
  )
);

export default function AccountMenu({ user }: { user: User }) {
  const [opened, setOpened] = useState(false);

  return (
    <Menu
      shadow="md"
      width={196}
      opened={opened}
      onChange={setOpened}
      trigger="hover"
      openDelay={100}
      closeDelay={400}
    >
      <Menu.Target>
        <UserButton
          image={user.user_metadata.avatar_url}
          name={user.user_metadata.full_name}
          email={user.email ?? 'email'}
          opened={opened}
        />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Account Menu</Menu.Label>
        <Menu.Item
          leftSection={<IconUser style={iconSize} />}
          component={Link}
          href="/account/profile"
        >
          Profile
        </Menu.Item>

        <Menu.Item
          leftSection={<IconCreditCard style={iconSize} />}
          component={Link}
          href="/account/billing"
        >
          Plan & Billing
        </Menu.Item>

        <Menu.Item
          leftSection={<IconSettings style={iconSize} />}
          component={Link}
          href="/account/settings"
        >
          Settings
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item leftSection={<IconLogout style={iconSize} />}>
          <form action={signOut}>
            <UnstyledButton type="submit">
              <Text fz="sm" span>
                Logout
              </Text>
            </UnstyledButton>
          </form>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
