'use client';

import type { Tables } from '@/types/supabase.types';
import {
  AppShell,
  AppShellSection,
  Badge,
  Box,
  Group,
  NavLink,
  ScrollArea,
  Text,
} from '@mantine/core';
import {
  IconCalendar,
  IconChalkboard,
  IconFile,
  IconFolder,
  IconFolderOpen,
  IconFolders,
  IconLayout2,
  IconMessage,
  IconReport,
  IconUsersGroup,
} from '@tabler/icons-react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

export default function Navbar({ folders }: { folders: Tables<'folders'>[] }) {
  const pathname = usePathname();

  return (
    <AppShell.Navbar withBorder>
      {/* <LogoGroup />
      <Divider />
      <AccountMenu user={user} /> */}
      <AppShellSection>
        <Group p="sm">
          <Text fz="xs" fw={500} c="dimmed" tt="uppercase">
            Dashboard
          </Text>
        </Group>
        {/* <AppSpotlight /> */}
        <NavLink
          component={Link}
          href="/dashboard"
          label="Home"
          leftSection={<IconLayout2 size="1rem" stroke={1.5} />}
          active={pathname === '/dashboard'}
        />
        <NavLink
          component={Link}
          href="/dashboard/reports"
          label="Reports"
          leftSection={<IconReport size="1rem" stroke={1.5} />}
          active={pathname === '/dashboard/reports'}
        />
        <NavLink
          component={Link}
          href="/dashboard/calendar"
          label="Calendar"
          leftSection={<IconCalendar size="1rem" stroke={1.5} />}
          active={pathname === '/dashboard/calendar'}
        />
      </AppShellSection>

      <AppShellSection>
        <NavLink
          component={Link}
          href="/team"
          label="Team"
          leftSection={<IconUsersGroup size="1rem" stroke={1.5} />}
          rightSection={<Badge size="xs">PRO</Badge>}
          active={pathname === '/team'}
          disabled
        >
          <NavLink
            component={Link}
            href="/team/board"
            label="Board"
            leftSection={<IconChalkboard size="1rem" stroke={1.5} />}
            active={pathname === '/team/board'}
            style={{ borderLeft: '1px solid var(--mantine-color-gray-light)' }}
          />
          <NavLink
            component={Link}
            href="/team/documents"
            label="Documents"
            leftSection={<IconFile size="1rem" stroke={1.5} />}
            active={pathname === '/team/documents'}
            style={{ borderLeft: '1px solid var(--mantine-color-gray-light)' }}
          />
          <NavLink
            component={Link}
            href="/team/chat"
            label="Chat"
            leftSection={<IconMessage size="1rem" stroke={1.5} />}
            rightSection={
              <Badge size="xs" color="red" circle>
                3
              </Badge>
            }
            active={pathname === '/team/chat'}
            style={{ borderLeft: '1px solid var(--mantine-color-gray-light)' }}
          />
        </NavLink>
      </AppShellSection>

      <AppShellSection grow component={ScrollArea}>
        <Group p="xs">
          <Text fz="xs" fw={500} c="dimmed" tt="uppercase">
            Inventory
          </Text>
        </Group>
        <NavLink
          component={Link}
          label="All Items"
          href="/items"
          leftSection={<IconFolders size="1rem" stroke={1.5} />}
          active={pathname === '/items'}
        />
        <Box pl="lg" className="mantine-NavLink-children">
          {folders
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((folder) => (
              <NavLink
                key={folder.id}
                component={Link}
                href={`/items/${folder.slug}`}
                label={folder.name}
                active={pathname === `/items/${folder.slug}`}
                color={folder.color || 'indigo'}
                leftSection={
                  pathname === `/items/${folder.slug}` ? (
                    <IconFolderOpen
                      size="1rem"
                      stroke={1.5}
                      color={
                        folder?.color ? `var(--mantine-color-${folder.color}-text)` : 'currentColor'
                      }
                    />
                  ) : (
                    <IconFolder
                      size="1rem"
                      stroke={1.5}
                      color={
                        folder?.color ? `var(--mantine-color-${folder.color}-text)` : 'currentColor'
                      }
                    />
                  )
                }
                style={{
                  borderLeft: `1px solid var(--mantine-color-${folder.color || 'gray'}-light)`,
                }}
              />
            ))}
        </Box>
      </AppShellSection>
      {/* <AppShellSection>
        <Box m="xs">
          <Button variant="default" component={Link} href="/subscriptions" fullWidth>
            Upgrade Plan
          </Button>
          <Text ta="center" fz="xs" c="dimmed" mt="xs">
            &copy; Invie {String(new Date().getFullYear())}; All rights reserved.
          </Text>
        </Box>
      </AppShellSection> */}
    </AppShell.Navbar>
  );
}
